




















export default {
  name: "StatsDetailsTable",
  props: {
    span: {},
    bookings: {},
    places: {}
  }
};
