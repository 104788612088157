










import Vue from "vue";
import { mapGetters } from "vuex";

import { Office } from "@/types";
import SelectOffice from "@/components/SelectOffice.vue";
import FloorsList from "@/components/FloorsList.vue";

export default Vue.extend({
  name: "BookingForm",
  components: { FloorsList, SelectOffice },
  data() {
    return {
      office: {} as Office
    };
  },
  computed: {
    ...mapGetters(["currentUser", "selectedOffice"])
  },
  watch: {
    selectedOffice(newValue) {
      if (newValue) {
        this.$store.dispatch("fetchBookings");
      }
    }
  }
});
