
























































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

import { Office, Place, QRCode } from "@/types";
import { getFloorPlan, generateQRCodes } from "@/services";

interface ChangeEventTarget<T> extends EventTarget {
  value: T;
}

interface ChangeEvent<T> extends Event {
  target: ChangeEventTarget<T>;
}

export default Vue.extend({
  name: "QRcodeForm",
  components: {},
  data() {
    return {
      selectedFloorId: "",
      selectedOfficeId: "",
      office: {} as Office,
      planImageUrl: "",
      qrcodes: undefined as QRCode[] | undefined
    };
  },
  async created() {
    await this.$store.dispatch("fetchOffices");
  },
  computed: {
    ...mapGetters(["offices"]),
    getOfficeOptions(): object[] {
      return this.offices.map((o: Office) => {
        return {
          name: o.name,
          id: o.id
        };
      });
    },
    places(): Place[] {
      const p = this.$store.getters.floorPlaces(this.selectedFloorId);
      if (p) {
        p.sort((a: Place, b: Place) => {
          return Number(a.number) - Number(b.number);
        });
      }
      return p;
    }
  },
  methods: {
    ...mapActions(["fetchPlaces"]),
    async fetchPlan(): Promise<void> {
      const planImage = await getFloorPlan(
        this.selectedFloorId,
        this.office.id
      );
      this.planImageUrl = "data:image/png;base64," + planImage;
    },

    async generate(): Promise<void> {
      this.qrcodes = await generateQRCodes(
        this.selectedOfficeId,
        this.selectedFloorId
      );
    },
    onSelectFloor(e: ChangeEvent<string>): void {
      const selectedFloorId = e.target.value;
      this.fetchPlan();
      this.fetchPlaces({ officeId: this.office.id, floorId: selectedFloorId });
    },
    onSelectOffice(e: ChangeEvent<string>): void {
      const selectedOfficeId = e.target.value;
      this.office = this.offices.find((o: Office) => o.id == selectedOfficeId);
    }
  }
});
