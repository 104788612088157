var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.office)?_c('div',{staticClass:"floors-list"},[_c('div',{staticClass:"floor"},[_c('div',{staticClass:"floor-selection row"},[_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedFloorId),expression:"selectedFloorId"}],staticClass:"select-css floor-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedFloorId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.onSelectFloor]}},_vm._l((_vm.office.floors),function(option,index){return _c('option',{key:'floor-' + option.id,domProps:{"value":option.id,"selected":index === 0}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)])]),_c('SelectDate',{staticClass:"row date-selection",on:{"change":_vm.setSelectedDate}}),(_vm.showPlan)?_c('div',{staticClass:"plan-container row"},[_c('img',{staticClass:"plan",attrs:{"src":_vm.planImageUrl,"alt":"Plan non disponible"}}),_vm._l((_vm.places),function(place){return _c('div',{key:'place-text-' + place.id},[(place.position)?_c('div',{staticClass:"place-label",style:({
            top: place.position.top + '%',
            left: place.position.left + '%',
            'background-color': _vm.isBooked(place.id)
              ? 'var(--booked)'
              : place.id === _vm.selectedPlaceId
              ? 'var(--selected)'
              : 'var(--free)',
            color: 'var(--text-darker)'
          }),on:{"click":function($event){return _vm.selectPlace(place.id)}}},[_vm._v(" "+_vm._s(place.number)+" ")]):_vm._e()])})],2):_vm._e(),(_vm.floor)?_c('div',{staticClass:"places"},[(_vm.$store.state.isUserAdmin)?_c('ul',[_c('li',[_vm._v(" Vous êtes administrateur, vous pouvez donc annuler toutes les réservations. ")]),_c('li',[_vm._v(" Attention : l'utilisateur n'est pas informé automatiquement : si vous devez annuler une réservation, veuillez le prévenir par mail. ")])]):_vm._e(),_c('div',{staticClass:"warning"},[_vm._v(" Confirmez votre présence avant 10h30 le jour J. ")]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedPlaceId),expression:"selectedPlaceId"}],staticClass:"select-css",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedPlaceId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.onSelectPlace]}},[_c('option',{attrs:{"value":"","disabled":"","selected":"","hidden":""}},[_vm._v(" Sélectionner un emplacement ")]),_vm._l((_vm.places),function(place){return _c('option',{key:'place' + place.id,attrs:{"disabled":_vm.isBooked(place.id)},domProps:{"value":place.id,"selected":place.id === _vm.selectedPlaceId}},[_vm._v(" Emplacement "+_vm._s(place.number)+" "+_vm._s(_vm.getFormatBookedEmail(place.id))+" ")])})],2)])]):_vm._e()],1),_c('button',{staticClass:"button button-primary book-button ",attrs:{"disabled":!_vm.selectedPlaceId},on:{"click":_vm.book}},[_vm._v(" Réserver la place ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }