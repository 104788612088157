<template>
  <div class="home">
    <div class="title" style="flex: 1">
      Bonjour {{ currentUser.given_name }},
    </div>
    <NextBooking style="flex: 1" class="next-booking-info"></NextBooking>

    <div style="flex: 1" class="bookings">
      <BookingCard></BookingCard>
    </div>
    <div style="flex: 1"></div>
    <button
      style="flex: 1"
      class="button button-rounded button-primary book-button"
      @click="goToBooking"
    >
      Faire une réservation
    </button>
  </div>
</template>

<script>
import NextBooking from "@/components/NextBooking";
import BookingCard from "@/components/BookingCard";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    BookingCard,
    NextBooking
  },
  async created() {
    await this.$store.dispatch("fetchNextBooking");
  },
  computed: {
    ...mapGetters(["currentUser"]),
    nextBooking() {
      return this.$store.getters.nextBooking;
    }
  },
  methods: {
    async goToBooking() {
      await this.$router.push({ name: "Booking" });
    },
    async goToMissingSupplies() {
      await this.$router.push({ name: "MissingSupplies" });
    }
  }
};
</script>

<style scoped="true">
.home {
  display: flex;
  flex-direction: column;
  width: 30rem;
  max-width: 85%;
  flex: 1;
}

.next-booking-info {
  margin-bottom: 1em;
}

.bookings {
  margin-top: 2em;
  width: 100%;
}

.confirm-button {
  align-self: center;
  width: 60%;
  margin-top: 0;
  margin-bottom: 2em;
}

.confirmed {
  background-color: var(--primary-light);
  cursor: default;
  box-shadow: none;
  color: var(--text-light);
  font-weight: 600;
}

.button-rounded {
  border-radius: 2rem;
}

.book-button {
  margin: 6em 3em;
  font-size: 19px;
  padding: 0em 0em;
  background-color: #6f75fe;
  display: block;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}

@media (max-width: 800px) {
  .book-button {
    margin: 4em 4em;
    font-size: 19px;
    padding: 0.1em 0em;
    font-weight: 900;
  }
}

@media (max-width: 420px) {
  .book-button {
    margin: 4em 0em;
    font-size: 19px;
    padding: 0.1em 0em;
    font-weight: 900;
  }
}

.button-icon {
  font-size: 2rem;
  background-color: var(--background-buttons);
  color: var(--text-light);
  min-width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
</style>
