













































import { getStats } from "@/services";
import { format } from "date-fns";
import Vue from "vue";
import { BookingStats } from "@/types";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import MultiSelectOffice from "@/components/MultiSelectOffice.vue";
import differenceInBusinessDays from "date-fns/differenceInBusinessDays";
import StatsDisplay from "@/views/StatsDisplay.vue";

export default Vue.extend({
  name: "Stats",
  components: {
    StatsDisplay,
    MultiSelectOffice,
    Datepicker
  },
  data() {
    return {
      stats: {} as BookingStats,
      fr: fr,
      selectedDate: new Date() as Date,
      selectedEndDate: new Date() as Date,
      selectedOffices: [] as string[]
    };
  },
  methods: {
    async loadStats(): Promise<void> {
      const startDateString = format(this.selectedDate, "yyyyMMdd");
      const endDateString = format(this.selectedEndDate, "yyyyMMdd");
      this.stats = await getStats(
        this.selectedOffices,
        startDateString,
        endDateString
      );
    },
    setSelectedEndDate(value: Date): void {
      this.selectedEndDate = value;
    },
    setSelectedDate(value: Date): void {
      this.selectedDate = value;
    },
    onSelectOffices(offices: string[]): void {
      this.selectedOffices = offices;
    }
  },
  computed: {
    span(): number {
      return (
        differenceInBusinessDays(this.selectedEndDate, this.selectedDate) + 1
      );
    }
  }
});
