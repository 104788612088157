<template>
  <div id="app">
    <div id="nav">
      <div class="app-title" @click="goHome">
        BOOK<span class="app-title-highlighted">A</span>DESK
        <span class="app-author">by <img src="./assets/logo-talan.png"/></span>
      </div>
    </div>
    <div class="dropdown" v-if="$store.state.isUserAdmin">
      <button @click="toggle" class="action-dropdown"
              :style="{
              'background-color': this.active === true
                ? '#8f97ff'
                : ''
            }"
      ><img src="./assets/burger.svg"/> </button>
      </div>
    <div class="dropdown">
      <div class="dropdown-content" v-if="active">
        <div class="column">
          <div
            class="nav-link"
            @click="$route.name !== 'Stats' && $router.push({ name: 'Stats' })"
          >
            Stats
          </div>
          <div
            class="nav-link"
            @click="
              $route.name !== 'EditOffice' &&
                $router.push({ name: 'EditOffice' })
            "
          >
            Edition
          </div>
          <div
            class="nav-link"
            v-if="$store.state.isUserAdmin"
            @click="
              $route.name !== 'GenerateQRCodes' &&
                $router.push({ name: 'GenerateQRCodes' })
            "
          >
            QRcodes
          </div>
        </div>
      </div>
    </div>
    <router-view class="view" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: false
    };
  },
  methods: {
    toggle() {
      this.active = !this.active;
    },
    goHome() {
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--background-color);
  background-image: url("./assets/pergolese-talan.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (max-height: 680px), screen and (orientation: portrait) {
  #app {
    background-image: url("./assets/pergolese-talan-small.jpg");
  }
}

#nav {
  padding: 30px;
  display: flex;
  justify-content: space-between;
}

.view {
  margin: auto;
  min-height: calc(100vh - 161px);
}
.back-arrow {
  cursor: pointer;
}

.info-button {
  background-color: var(--background-green);
  color: var(--text-light);
  min-width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.4em;
  cursor: pointer;
}

.app-title {
  color: var(--primary);
  font-weight: 700;
  font-size: 3em;
  font-variant: small-caps;
}

.app-title:hover {
  cursor: pointer;
}

.app-author {
  color: #000000;
  font-weight: 600;
  font-size: 0.4em;
  font-variant: all-small-caps;
  display: flex;
  align-items: center;
}

.app-title-highlighted {
  color: var(--primary-dark);
}

.action-dropdown {
  border-radius: 2em;

  width: 50px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: -4em 2em 1em auto;
  padding: 10px;
}

.dropdown-content {
  border: 1px solid var(--primary);
  border-radius: 2em;
  background-color: white;
  position: relative;
  z-index: 1;

  width: 10em;
  margin: 2em 2em 1em auto;

  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.selected {
  background-color: var(--primary);
  color: var(--text-light);
}

.nav-link {
  cursor: pointer;

  width: 100%;

  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.nav-link:hover {
  background-color: var(--primary);
  color: var(--text-light);
}

@media (max-width: 800px) {
  .action-dropdown {
    margin-right: 1em;
  }
}
</style>
