<template>
  <div class="card p-4 next-booking " v-if="booking && booking.date">
    <div class="booking-title">
      {{ booking.officeName }}
    </div>
    <div class="">
      {{ getFormattedDate(booking.date) }}
    </div>
    <div>
      <div class="">{{ booking.floorName }}</div>
      <div class="">Place {{ booking.placeName }}</div>
    </div>
    <div class="block-button">
      <CancelBooking
        :booking-id="booking.id"
        v-if="booking && !booking.confirmed"
      ></CancelBooking>
      <ConfirmBooking :booking-id="booking.id"></ConfirmBooking>
    </div>
  </div>
</template>

<script>
import { format, parse } from "date-fns";
import { fr } from "date-fns/locale";
import CancelBooking from "@/components/CancelBooking";
import ConfirmBooking from "@/components/ConfirmBooking";

export default {
  name: "BookingCard",
  components: { CancelBooking, ConfirmBooking },
  computed: {
    booking() {
      return this.$store.getters.nextBooking;
    }
  },
  methods: {
    getFormattedDate(date) {
      const parsed = parse(date, "yyyyMMdd", Date.now());
      return format(parsed, "dd/MM/yyyy", {
        locale: fr
      });
    }
  }
};
</script>

<style scoped="true">
.next-booking {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background-color: var(--background-one);
}

.booking-title {
  margin-bottom: 8px;
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  color: var(--text-title);
}

.block-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
