



























































































import Vue from "vue";
import { add, format } from "date-fns";
import { fr } from "date-fns/locale";
import { mapActions, mapGetters } from "vuex";
import SelectDate from "@/views/SelectDate.vue";

import { getFloorPlan, getOffice } from "@/services";
import {
  Booking,
  DayAlreadyBookedError,
  Floor,
  Office,
  Place,
  PlaceAlreadyBookedError
} from "@/types";
import config from "@/config";

interface ChangeEventTarget<T> extends EventTarget {
  value: T;
}

interface ChangeEvent<T> extends Event {
  target: ChangeEventTarget<T>;
}

export default Vue.extend({
  name: "FloorsList",
  components: { SelectDate },
  async created() {
    this.setSelectedDate(new Date());

    const query = this.$route.query;
    await this.loadPlaces();
    if (this.office && this.office.floors) {
      // init selected floor with first floor
      // or with last selected floor
      // or this floor id present in the query url
      this.selectedFloorId = this.office.floors[0].id;
      if (localStorage.selectedFloorId) {
        this.selectedFloorId = localStorage.selectedFloorId;
      }
      const foundFloor = this.office.floors.find(f => f.id === query.floorId);
      if (foundFloor) {
        this.selectedFloorId = foundFloor.id;
      }
    }
    await this.fetchPlaces({
      officeId: this.office.id,
      floorId: this.selectedFloorId
    });
    // init selected place if present in the query url
    const places = this.$store.getters.floorPlaces(this.selectedFloorId);
    const foundPlace = places.find((p: Place) => p.id === query.placeId);
    if (foundPlace) {
      this.isBooked(foundPlace.id)
        ? this.$toasted.error(
            "Désolé, cette place est déjà réservée. Veuillez réessayer avec une autre place"
          )
        : (this.selectedPlaceId = foundPlace.id);
    }
    await this.fetchPlan();
  },
  data() {
    return {
      office: {} as Office,
      selectedPlaceId: "",
      showPlan: !config.hidePlans,
      selectedFloorId: "",
      planImageUrl: ""
    };
  },
  computed: {
    ...mapGetters(["currentUser", "selectedDate", "selectedOffice"]),
    floor(): Floor | undefined {
      if (this.office && this.office.floors) {
        return this.office.floors.find(
          (f: Floor) => f.id === this.selectedFloorId
        );
      }
      return undefined;
    },
    dateOptions() {
      function getLabelForDate(daysOffset: number) {
        return format(add(new Date(), { days: daysOffset }), "cccc d MMM", {
          locale: fr
        });
      }

      function getOption(offsetInDays: number) {
        return {
          text: getLabelForDate(offsetInDays),
          value: offsetInDays
        };
      }

      const options = [
        { text: "Aujourd'hui", value: 0 },
        { text: "Demain", value: 1 },
        getOption(2),
        getOption(3),
        getOption(4),
        getOption(5),
        getOption(6),
        getOption(7)
      ];
      return options.filter(o => {
        const day = add(new Date(), { days: o.value }).getDay();
        return day !== 0 && day !== 6;
      });
    },
    places(): Place[] {
      const p = this.$store.getters.floorPlaces(this.selectedFloorId);
      if (p) {
        // sort booked place at the end of the list
        p.sort((a: Place, b: Place) => {
          if (this.isBooked(a.id) !== this.isBooked(b.id)) {
            return this.isBooked(a.id) ? 1 : -1;
          } else {
            // Sort places in ascending order
            return Number(a.number) - Number(b.number);
          }
        });
      }
      return p;
    },
    showPlanButton(): boolean {
      return !config.hidePlans;
    }
  },
  methods: {
    ...mapActions(["fetchPlaces"]),
    async book() {
      if (this.isBooked(this.selectedPlaceId)) {
        return;
      }
      const options = {
        placeId: this.selectedPlaceId,
        officeId: this.selectedOffice,
        date: this.selectedDate
      };

      const result = await this.$store.dispatch("confirmBooking", options);
      if (!result) {
        this.$toasted.success("Réservation effectuée");
      } else if (result instanceof PlaceAlreadyBookedError) {
        this.$toasted.error(
          "Désolé, cette place est déjà réservée. Veuillez réessayer avec une autre place"
        );
      } else if (result instanceof DayAlreadyBookedError) {
        this.$toasted.error("Vous avez déjà réservé une place pour ce jour");
      } else {
        this.$toasted.error("Erreur inattendue, veuillez réessayer");
      }
      this.selectedPlaceId = "";
    },
    setSelectedDate(newDate: Date) {
      const dateString = format(newDate, "yyyyMMdd");
      this.$store.commit("setSelectedDate", dateString);

      if (this.$store.getters.selectedOffice)
        this.$store.dispatch("fetchBookings");
    },
    async fetchPlan() {
      const planImage = await getFloorPlan(
        this.selectedFloorId,
        this.office.id
      );
      this.planImageUrl = "data:image/png;base64," + planImage;
    },
    async selectPlace(placeId: string) {
      if (this.isBooked(placeId)) {
        return;
      }
      this.selectedPlaceId = placeId;
    },
    async loadPlaces() {
      this.office = await getOffice(this.selectedOffice);
    },
    getFormatBookedEmail(placeId: string): string {
      const value = this.getBookedEmail(placeId);
      return value !== "" ? ` (${value})` : "";
    },
    getBookedEmail(placeId: string): string {
      const booking = this.getBooking(placeId);
      if (!booking || !booking.email) {
        return "";
      }
      return booking.email;
    },
    getBooking(placeId: string): Booking | undefined {
      return this.$store.getters.booking(placeId, this.selectedDate);
    },
    isBooked(placeId: string): boolean {
      return !!this.getBooking(placeId);
    },
    onSelectFloor(e: ChangeEvent<string>): void {
      const selectedFloorId = e.target.value;
      this.fetchPlan();
      this.fetchPlaces({ officeId: this.office.id, floorId: selectedFloorId });
      localStorage.selectedFloorId = selectedFloorId;
    },
    onSelectPlace(e: ChangeEvent<string>): void {
      this.selectedPlaceId = e.target.value;
    }
  }
});
