<template>
  <button @click="onClickDelete" class="button cancel-button">
    Annuler
  </button>
</template>

<script>
export default {
  name: "CancelBooking",
  props: {
    bookingId: String
  },
  methods: {
    async onClickDelete() {
      const result = await this.$store.dispatch(
        "deleteBookings",
        this.bookingId
      );
      if (result instanceof Error) {
        this.$toasted.error("Erreur lors de l'annulation de la réservation  ");
      } else {
        this.$toasted.success("Réservation annulée");
      }
    }
  }
};
</script>

<style scoped>
.cancel-button {
  margin-top: 0.5rem;
  font-size: 1rem;
  border-radius: 30px;
  background-color: var(--background-one);
  color: var(--text-ghost);
  padding: 12px 24px;
}
.cancel-button:hover {
  box-shadow: 2px 2px 2px var(--shadow);
}
</style>
