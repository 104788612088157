

































































import StatsDetailsTable from "@/views/StatsDetailsTable.vue";
import { BookingStats } from "@/types";

export default {
  name: "StatsDisplay",
  components: { StatsDetailsTable },
  props: {
    span: {} as number,
    stats: {} as BookingStats
  }
};
