<template>
  <div>
    <button
      @click="confirmPresence"
      class="button confirm-button"
      v-if="!nextBooking.confirmed && !isConfirmationDisabled()"
    >
      Confirmer ma présence
    </button>
    <div v-if="nextBooking.confirmed" class="confirmed-container">
      <code class="icon-check">&check;</code>
      <div class="confirmed-label">Présence validée</div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  name: "ConfirmBooking",
  props: {
    bookingId: String
  },
  computed: {
    nextBooking() {
      return this.$store.getters.nextBooking;
    }
  },
  methods: {
    async confirmPresence() {
      if (this.isConfirmationDisabled()) {
        return;
      }
      const res = await this.$store.dispatch("confirmPresence");
      if (res instanceof Error) {
        this.$toasted.error("Erreur lors de la confirmation");
      } else {
        this.$toasted.success("Présence confirmée");
      }
    },
    isConfirmationDisabled() {
      const todayString = format(Date.now(), "yyyyMMdd");
      return !this.nextBooking || this.nextBooking.date !== todayString;
    }
  }
};
</script>

<style scoped>
.confirm-button {
  margin-top: 8px;
  margin-left: 8px;
  background-color: var(--background-buttons);
  border-radius: 30px;
  color: var(--text-light);
  padding: 12px 24px;
}
.confirm-button:hover {
  background-color: var(--background-buttons-hover);
}
.confirmed-container {
  background-color: var(--confirm);
  color: var(--text-light);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  min-height: 2rem;
}
.confirmed-label {
  text-align: center;
  padding: 12px 24px 12px 4px;
}
.icon-check {
  background-color: var(--background-one);
  color: var(--text-title);
  border-radius: 50%;
  margin: auto 0.125rem auto 0.5rem;
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  min-width: 24px;
  height: 24px;
}
</style>
